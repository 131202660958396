.App {
    text-align: center;
    font-family: arial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .Menu {
    width: 500px;
    height: 500px;
    border-radius: 10px;
    background-color: lightseagreen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Menu input, button {
    width: 300px;
    min-height: 7vh;
    margin: 5px;
    border: none;
    border-radius: 5px;
    font-size: 0.8em;
    padding: 9px;
  }
  
  button:hover {
    cursor: pointer;
  }
  
  .Quiz {
    width: 500px;
    height: 500px;
    border-radius: 10px;
    background-color: lightseagreen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .Menu, .Quiz, .EndScreen h1 {
    color: white;
  }
  
  .EndScreen {
    width: 500px;
    height: 500px;
    border-radius: 10px;
    background-color: lightseagreen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #nextQuestion {
    width: 200px;
  }
  
  h3 {
    color: #ffffff;
  
  }