@import url("https://fonts.googleapis.com/css?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Rubik';
}

body {
  /* background: rgb(4, 55, 242);
   */

  background: rgba(0, 41, 205, 0.06);
  color: rgba(255, 255, 255, 0.994);
  position: relative;
  /* height: 98%; */
  max-width: 100%;
  overflow-x: hidden;
  /* min-height: 120vh; */
}


.App {
  font-family: 'Rubik';
  text-align: center;
}

.recharts-cartesian-axis-tick {
  font-size: 0.6rem;
  font-family: Roboto, sans-serif;
}

a {
  text-decoration: none;
  color: white;
}



@keyframes animate_01 {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}



@keyframes descriptbox {
  0% {
    transform: translate(-10px, 50px) scale(0.9) rotate(0deg);
  }
}


.loader-pages{
  width: 80px;
  height: 70px;
  position: fixed;
  right: 50%;
  top:0;
  min-height: 45px;
  background: rgb(2, 255, 133);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  z-index: 434343;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  animation: descriptbox 2s ease-in-out ;
}

.load-pages{
  width: 60px;
  height: 50px;
  position: fixed;
  top:5%;
  /* right: 50%; */
  min-height: 45px;
  background: rgb(0, 255, 68);
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  z-index: 44334343;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  animation: descriptbox 2s ease-in-out ;
}

.body{
  overflow-x: hidden;
}


@media screen and (max-width: 640px) {

  .loader-pages{
    width: 80px;
    height: 70px;
    position: fixed;
    right:0;
    /* right: 50%; */
    min-height: 45px;
    background: rgb(0, 85, 255);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    z-index: 434343;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
    animation: descriptbox 2s ease-in-out ;
  }
  


}