body {
    background-color: white;
  }
  
  .ssd p{
    font-size: 0.8em;
  }

  
  
  @media screen and (min-width: 940px) {
    #dds{
  position: relative;
  left: 160px;

    }
    .welcome-pages {
    
      min-height: 110vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      background-image: linear-gradient(
          rgba(11, 1, 67, 0.699),
          rgba(3, 0, 37, 0.932)
        ),
        url("../../images/crdblogo.png");
    }
  
    #sumos {
      margin: 10px;
      font-weight: bold;
    }
  
    .login-page {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      object-fit: cover;
      flex-direction: column;
    }
  
    .login-left {
      width: 500px;
      height: 500px;
      background: linear-gradient(
        to top left,
        rgba(142, 206, 2, 0.303),
        rgba(3, 4, 49, 0.483)
      );
      border-radius: 50%;
      position: relative;
      top: 80px;
      left: -350px;
      box-shadow: 1px 11px 52px 22px rgba(255, 255, 255, 0.297);
    }
    .login-img {
      position: absolute;
      width: 600px;
      left: 23px;
      height: 600px;
      border-radius: 53% 47% 50% 50% / 38% 41% 59% 62%;
      box-shadow: 1px 11px 82px 22px rgba(1, 68, 131, 0.297);
    }
  
    /* .cover{
      position: absolute;
      width: 100%;
      height: 800px;
      background: linear-gradient(to right top, rgba(4, 4, 65, 0.298),rgb(64, 0, 128));
  } */
  
    .login-right {
      width: 30%;
      height: auto;
      position: absolute;
      top: 140px;
      right: 80px;
      background: rgba(255, 255, 255, 0.085);
      backdrop-filter: blur(12px);
      border-radius: 20px;
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 40px;
      padding-bottom: 50px;
    }
  
    .login-data {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  
    .forms {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      margin-top: 23px;
      /* background-color: white; */
      margin-bottom: 23px;
    }
  
    .forms .input-one {
      display: flex;
      gap: 10px;
      position: relative;
    }
  
    .forms .input-two {
      display: flex;
      gap: 10px;
      position: relative;
    }
  
    .login-data p:nth-child(1) {
      font-size: 1.5em;
      font-weight: bold;
    }
  
    .login-data p:nth-child(2) {
      font-size: 0.9em;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.746);
    }
  
    .input-one input {
      background: transparent;
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.368);
      height: 45px;
      width: 100%;
      border-radius: 5px;
      padding-left: 12px;
      font-size: 0.8em;
    }
  
    .input-two input {
      background: transparent;
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.368);
      height: 45px;
      width: 100%;
      border-radius: 5px;
      padding-left: 12px;
      font-size: 0.8em;
    }
  
    .input-one input::placeholder {
      font-weight: 300;
      font-size: 0.8em;
    }
  
    .input-two input::placeholder {
      font-weight: 300;
      font-size: 0.8em;
    }
  
    .remeber {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 9px;
      margin-left: -142px;
    }
  
    .remeber input {
      width: 14px;
      height: 14px;
      border-radius: 5px;
      border: none;
      outline: none;
    }
  
    input {
      color: rgba(255, 255, 255, 0.871);
      font-size: 0.8em;
    }
  
    .input-one input:focus {
      border: 2px solid rgba(0, 248, 50, 0.351);
    }
  
    .input-two input:focus {
      border: 2px solid rgba(0, 248, 50, 0.312);
    }
  
    button {
      cursor: pointer;
    }
  
    .remeber p {
      font-size: 0.8em;
    }
    .remember-opt {
      width: 100%;
      /* margin: 23px; */
    }
    .remember-opt .sign-btn {
      height: 45px;
      width: 100%;
      border-radius: 6px;
      background: rgb(15, 211, 126);
      outline: none;
      color: rgb(255, 255, 255);
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 5px;
      border: 2px solid rgba(255, 255, 255, 0.1);
    }
  
    .anaccount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 3px;
    }
  
    .anaccount p {
      font-size: 0.9em;
      font-weight: bold;
      color: white;
    }
  
    .account {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      position: absolute;
      bottom: -94px;
      left: 25%;
    }
  
    .accounts {
      display: flex;
      /* flex-direction: column; */
      justify-content: space-around;
      align-items: center;
      gap: 10px;
      /* position: absolute; */
      bottom: -44px;
      left: 27%;
      margin: 12px;
    }
  
    .atts{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 23px;
    }
  
    .atts button:nth-child(1){
     width: 110px;
     height: 40px;
     border: none;
     border-radius: 4px;
     background: transparent;
     background: rgba(255, 255, 255, 0.326);
     color: white;
     
    }
  
    .atts button:nth-child(2){
      width: 110px;
      height: 40px;
      border: none;
      border-radius: 4px;
      background: transparent;
      background: rgba(255, 255, 255, 0.05);
      color: white;
     }
  
     .bbdad:active{
      background: rgba(255, 255, 255, 0.278);
     }
  
    .accounts p {
      font-size: 0.8em;
    }
  
    #forgot {
      color: rgba(253, 253, 255, 0.556);
      cursor: pointer;
    }
    .acc {
      font-size: 0.8em;
    }
  
    .account p:nth-child(2) {
      color: rgb(67, 129, 255);
      font-size: 0.9em;
      font-weight: bold;
      cursor: pointer;
    }
  
    span {
      color: rgb(67, 129, 255);
      font-size: 1em;
      font-weight: bold;
      cursor: pointer;
    }
  
    .body-contentcc {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 12px;
      position: relative;
      top: 124px;
    }
  
    .spin {
      margin: 12px;
    }
  
    .highlight-anim {
      margin-right: -632px;
      position: relative;
      margin-top: -54px;
    }
  
    .ouur {
      width: 100%;
      min-height: 22vh;
      border: 1px solid rgba(255, 255, 255, 0.183);
      margin-top: -73px;
      border-radius: 23px;
      padding: 23px;
      backdrop-filter: blur(5px);
      animation: dscbox 2s ease-in-out;
      background: linear-gradient(#05149c0b, #ffffff29);
      /* position: absolute; */
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;
      /* right: 114px; */
    }


    .ouurr {
      width: 100%;
      min-height: 22vh;
      border: 1px solid rgba(255, 255, 255, 0.183);
      margin-top: -73px;
      border-radius: 23px;
      padding: 23px;
      backdrop-filter: blur(5px);
      animation: dscbox 2s ease-in-out;
      background: linear-gradient(#05149c0b, #ffffff29);
      position:relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;
      
      /* right: 114px; */
    }
  
    .ouu {
      min-width: auto;
      min-height: 22vh;
      border: 1px solid rgba(255, 255, 255, 0.183);
      margin-top: -43px;
      border-radius: 23px;
      padding: 23px;
      backdrop-filter: blur(5px);
      animation: dscbox 2s ease-in-out;
      background: linear-gradient(#05149c0b, #ffffff29);
      /* position: absolute; */
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;
    }
  
    .sdas {
    
      width: 85px;
      height: 85px;
      background: rgba(255, 255, 255, 0.224);
      border-radius: 50%;
      display: flex;
      /* flex-direction: column; */
      justify-content: center;
      align-items: center;
      box-shadow: 2px 35px 35px -4px rgba(255, 255, 255, 0.364),
        inset 15px 15px 30px rgba(7, 110, 255, 0.327),
        inset 1px -1px 14px rgba(255, 255, 255, 0.723);
    }
  
    .stat {
      background-color: rgba(255, 255, 255, 0.081);
      height: 40px;
      width: 100%;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9em;
    }
  
    .sdas img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
  
    .sdacont {
      min-height: 20vh;
      backdrop-filter: blur(4px);
      min-width: 360px;
      background: rgba(255, 255, 255, 0.001);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 12px;
      text-align: center;
      border-bottom: 3px solid rgba(36, 166, 242, 0.519);
    }

    .body-contentcc {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 12px;
      position: relative;
      width: 50%;
      /* top: 124px; */
    }
    .sdaconts{
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 940px) {
    .welcome-pages {
      min-height: 100vh;
      /* display: flex; */
      flex-direction: column;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 100%;
      display: flex;
      justify-content: center;
      /* align-items: center; */
      background-image: linear-gradient(
          rgba(11, 1, 67, 0.699),
          rgba(0, 37, 29, 0.932)
        ),
        url("../../images/crdblogo.png");
    }

  
    .atts{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      gap: 23px;
    }

    .body-contentcc{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .atts button:nth-child(1){
     width: 110px;
     height: 40px;
     border: none;
     border-radius: 4px;
     background: transparent;
     background: rgba(255, 255, 255, 0.326);
     color: white;
     
    }
  
    .atts button:nth-child(2){
      width: 110px;
      height: 40px;
      border: none;
      border-radius: 4px;
      background: transparent;
      background: rgba(255, 255, 255, 0.05);
      color: white;
     }
    #sumos {
      margin: 10px;
      font-size: 0.9em;
      /* font-weight: bold; */
    }
  
    .login-page {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      object-fit: cover;
      flex-direction: column;
    }

  
    .spin {
      margin: 12px;
    }
  
    .ouu {
      width: 100%;
      min-height: 25vh;
      border: 1px solid rgba(255, 255, 255, 0.183);
      /* margin-top: -43px; */
      border-radius: 23px;
      padding: 23px;
      backdrop-filter: blur(3px);
      animation: dscbox 2s ease-in-out;
      background: linear-gradient(#05149c0b, #ffffff29);
      /* position: absolute; */
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;
    }
  
    
    .ouur {
      width: 99%;
      min-height: 25vh;
      border: 1px solid rgba(255, 255, 255, 0.183);
      /* margin-top: -73px; */
      border-radius: 23px;
      padding: 23px;
      backdrop-filter: blur(5px);
      animation: dscbox 2s ease-in-out;
      background: linear-gradient(#05149c0b, #ffffff29);
      /* position: absolute; */
      display: flex;
      /* justify-content: center; */
      flex-direction: column;
      align-items: center;
      gap: 10px;
      position: relative;
      /* right: 114px; */
    }
  
    .sdas {
      width: 75px;
      height: 75px;
      background: rgba(255, 255, 255, 0.224);
      border-radius: 50%;
      display: flex;
      /* flex-direction: column; */
      justify-content: center;
      align-items: center;
      box-shadow: 2px 35px 35px -4px rgba(255, 255, 255, 0.364),
        inset 15px 15px 30px rgba(7, 110, 255, 0.327),
        inset 1px -1px 14px rgba(255, 255, 255, 0.723);
    }
  
    .sdas img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  
    .sdacontsa {
      min-height: 10vh;
      border: 2px solid rgba(8, 221, 8, 0.222);
      backdrop-filter: blur(4px);
      min-width: 100%;
      background: rgba(1, 37, 114, 0.041);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 12px;
      text-align: center;
      /* border: 1px solid rgba(255, 255, 255, 0.139); */
    }

    .sdaconts {
      min-height: 10vh;
      width: 350px;
      /* border: 2px solid rgba(8, 221, 8, 0.222); */
      backdrop-filter: blur(4px);
 /* position: absolute; */
      /* margin-left: -93px; */
      background: rgba(1, 37, 114, 0.041);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 12px;
      text-align: center;
      border: 1px solid rgba(255, 255, 255, 0.139);
    }
  
    .anaccount {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 3px;
      /* width: 100%; */
    }
  
    .anaccount p {
      font-size: 0.8em;
      /* font-weight: bold; */
      font-size: 0.9em;
      font-weight: bold;
      color: white;
    }
  
    .forms {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 90%;
      margin-top: 23px;
      margin-bottom: 23px;
      /* display: ; */
    }
  
    .forms .input-one {
      display: flex;
      gap: 10px;
      position: relative;
    }
  
    .forms .input-two {
      display: flex;
      gap: 10px;
      position: relative;
    }
  
    .input-one input {
      background: transparent;
      outline: none;
      border: 1px solid rgb(255, 255, 255);
      height: 40px;
      width: 100%;
      border-radius: 5px;
      padding-left: 12px;
    }
  
    .input-two input {
      background: transparent;
      outline: none;
      border: 1px solid rgba(255, 255, 255, 0.743);
      height: 40px;
      width: 100%;
      border-radius: 5px;
      padding-left: 12px;
    }
  
    .input-one input::placeholder {
      font-weight: 300;
      color: rgba(255, 255, 255, 0.6);
    }
  
    .input-two input::placeholder {
      font-weight: 300;
      color: rgba(255, 255, 255, 0.6);
    }
  
    .remeber {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 9px;
      margin-left: -142px;
    }
  
    .remeber input {
      width: 14px;
      height: 14px;
      border-radius: 5px;
      border: none;
      outline: none;
    }
  
    input {
      color: rgba(255, 255, 255, 0.871);
    }
  
    .input-one input:focus {
      border: 2px solid rgba(0, 248, 50, 0.323);
    }
  
    .input-two input:focus {
      border: 2px solid rgba(0, 248, 50, 0.318);
    }
  
    .remeber p {
      font-size: 0.8em;
    }
    .remember-opt {
      width: 60%;
      margin: 23px;
    }
    .remember-opt .sign-btn {
      height: 40px;
      width: 100%;
      border-radius: 6px;
      background: rgb(15, 211, 126);
      outline: none;
      color: rgb(255, 255, 255);
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 5px;
      border: 2px solid rgba(255, 255, 255, 0.1);
    }
    input {
      color: rgba(255, 255, 255, 0.871);
    }
  
    .remeber {
      opacity: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 9px;
      margin-left: -142px;
    }
  
    .remeber input {
      width: 14px;
      height: 14px;
      border-radius: 5px;
      border: none;
      outline: none;
    }
  
    .input-one input:focus {
      border: 2px solid rgba(0, 248, 50, 0.362);
    }
  
    .input-two input:focus {
      border: 2px solid rgba(0, 248, 50, 0.389);
    }
  
    button {
      cursor: default;
    }
  
    .remeber p {
      font-size: 0.8em;
    }
    .remember-opt {
      width: 60%;
      margin: 23px;
    }
  
    .remember-opt .sign-btn {
      height: 40px;
      width: 100%;
      border-radius: 6px;
      background: rgb(15, 211, 126);
      outline: none;
      color: rgb(255, 255, 255);
      padding-left: 12px;
      padding-right: 12px;
      border-radius: 5px;
      border: 2px solid rgba(255, 255, 255, 0.558);
    }
  
    .stat {
      background-color: rgba(255, 255, 255, 0.081);
      height: 40px;
      width: 60%;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
    }
  
    .stat p {
      font-size: 0.9em;
    }
  
    .accounts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      /* position: absolute; */
      bottom: -44px;
      left: 27%;
      margin: 12px;
    }
  
    .accounts p {
      font-size: 0.7em;
    }
  
    #forgot {
      color: rgba(253, 253, 255, 0.556);
      cursor: default;
    }

  }

  .ssd p{
    color: rgba(255, 255, 255, 0.463);
    font-size: 0.64em;
  }



  .sign-btn{
    font-size: 0.89em;
  }


  
  .sdacontss {
    min-height: 20vh;
    backdrop-filter: blur(4px);
    min-width: 100%;
    background: rgba(1, 37, 114, 0.041);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 12px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.139);
  }




  .sdacontss button{
    width: 100%;
    background: rgba(255, 255, 255, 0.186) ;
    border: 1px solid rgba(255, 255, 255, 0.252);
   font-size: 0.8em;
  text-align: start;
   padding-left: 23px;
   backdrop-filter: blur(4px);
   color: rgba(246, 238, 238, 0.859);
   border-left: 5px solid rgba(255, 255, 255, 0.46);
   border-right: 5px solid rgba(255, 255, 255, 0.46);
   /* border-right: 9px solid rgba(0, 89, 255, 0.46); */
  }

button.selected {
  background-color: rgba(7, 255, 7, 0.126);
  color: rgb(3, 255, 3);
  border-left: 9px solid rgba(0, 255, 26, 0.46);
   border-right: 9px solid rgba(0, 255, 26, 0.46);
}

#nextQuestion{
  font-size: 0.8em;
  background-color: rgba(0, 255, 55, 0.06);
  color: white;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.407);
  margin: 23px;
}

#scores{
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.548);
}

.scores{
  width: 60%;
  height: 7vh;
  display: flex;
  align-items: center;
  border-left: 3px solid  rgba(255, 255, 255, 0.46);
  border-right: 3px solid rgba(255, 255, 255, 0.46);
  justify-content: center;
  background: rgba(255, 255, 255, 0.058);
}

#restart{
  background: rgba(79, 255, 21, 0.116);
  width: 50%;
  color: white;
  height: 50px;
  border-bottom: 1px solid rgb(0, 246, 0);

}


#restarts{
  background: rgba(79, 255, 21, 0.116);
  width: 100%;
  color: white;
  height: 50px;
  border-bottom: 1px solid rgb(0, 246, 0);

}


#noscores{
  font-size: 0.9em;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.664);
}

.noscores{
  width: 60%;
  height: 7vh;
  display: flex;
  align-items: center;
  border-left: 3px solid rgba(255, 4, 4, 0.345);
  border-right: 3px solid rgba(255, 4, 4, 0.345);
  justify-content: center;
  background: rgba(236, 247, 255, 0.212);
}

.names{
  width: 100%;
  height: 7vh;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.award{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 60vh;
  border: 1px solid rgba(255, 255, 255, 0.065);
  border-radius: 12px;
  /* background: white; */
  padding: 23px;
  backdrop-filter: blur(34px);;
  gap: 23px;
}


.awad{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 50vh;
  border-radius: 12px;
  flex-direction: column;
  gap: 13px;
  /* background: white; */
  
}

.youwon{

  height: 40px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.046);
}

.youwon p{
  font-size: 0.9em
}

.stato{
  width: 100%;
  height: 100%;
  padding: 12px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgb(8, 76, 25);
}

.sdaconts  h2{

  font-size: 0.9em;
}

.ao{
  position: absolute;
  bottom: -52px;
  width: 60%;
  height: 40px;
  left: 34%;
}




